.loans {
  flex-grow: 1;
  position: relative;
}
.loans__tickers {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
}
.loans__ticker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  background-color: $blue-pastel;
  padding: 5px 10px;
  border-radius: 10px;
  width: 150px;
  margin-left: 10px;
  img {
    height: 1.25rem;
  }
}
.loans__none {
  position: absolute;
  bottom: 0;
  width: 40%;
}

.loans__loan {
  cursor: pointer;
}
.loans__loan__top {
  display: flex;
  align-items: center;
  background-color: $blue-pastel;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 25px;
  gap: 30px;
  img {
    height: 40px;
  }
}
.loans__loan__bottom {
  background-color: $blue;
  position: relative;
  bottom: 15px;
  padding: 25px;
  border-radius: 30px;
  .off__white {
    color: #f1f5fe;
  }
}
@media only screen and (max-width: 899px) {
  .loans__tickers {
    position: relative;
    margin: 25px 0;
  }
  .loans__none {
    margin: auto;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 599px) {
  .loans__tickers {
    flex-direction: column;
  }
  .loans__ticker {
    margin: 5px 0;
  }
}
.loans__mobile__new {
    display: none;
}
@media only screen and (max-width: 999px) {
  .loans__mobile__new {
    display: block;
    @include flex-center;
    svg {
      color: white;
      margin-left: 10px;
    }
  }
}