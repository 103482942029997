.help {
  flex-grow: 1;
  position: relative;
}
.help__grid {
  margin-top: 40px !important;
}
.help__bg {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
}
@media only screen and (max-width: 999px) {
  .help__bg {
    display: none;
  }
}