.onboarding {
  background-color: $blue-pastel;
  height: 100vh;
  @include flex-center;
  .MuiStepper-root {
    .MuiSvgIcon-root {
      color: white;
    }
    .MuiStepIcon-text {
      fill: $blue;
    }
    .MuiStepLabel-label {
      color: white;
      font-weight: bold;
    }
  }
  .MuiStepper-root .Mui-completed {
    .MuiSvgIcon-root {
      color: $green;
      transition: 0.5s;
    }
    .MuiStepLabel-label {
      color: $green;
      transition: 0.5s;
    }
  }
}
.onboarding__container {
  display: flex;
  @include modal-radius;
  overflow: hidden;
}
.onboarding__left {
  background-color: $blue;
  padding: 40px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.onboarding__left__image {
  height: 200px;
  max-width: 100%;
}
.onboarding__right {
  background-color: white;
  padding: 20px;
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
}
.onboarding__right__top {
  color: $grey;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.onboarding__right__top__help {
  color: $blue;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.onboarding__right__steps {
  padding: 20px;
  position: relative;
  flex-grow: 1;
}
.onboarding__tos__cta {
  color: $blue;
  display: block;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    animation: slide infinite 0.5s ease-in-out;
  }
}
.onboarding__tos__dialog {
  .MuiPaper-root {
    padding: 35px;
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
// controls
.onboarding__controls {
}
.onboarding__controls__back {
  color: $blue;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 0;
}
.onboarding__controls__next {
  display: inline-flex;
  align-items: center;
  margin: 0;
  position: absolute;
  right: 20px;
  bottom: 0;
}
@media only screen and (max-width: 1025px) {
  .onboarding {
    min-height: 100vh;
    padding: 25px 5px;
    height: auto;
  }
  .onboarding__container {
    flex-direction: column;
  }
  .onboarding__left {
    width: auto;
    text-align: center;
    display: block;
    img {
      max-width: 50%;
      margin-bottom: 25px;
    }
  }
  .onboarding__left__image {
    display: none;
  }
  .onboarding__right__top {
    flex-direction: column-reverse;
  }
  .onboarding__right {
    width: auto;
    height: auto;
  }
  .onboarding__controls {
    margin-top: 25px;
  }
  .onboarding__controls__back, .onboarding__controls__next {
    position: relative;
  }
  .onboarding__controls__next {
    float: right;
    right: 0;
  }
  .onboarding__controls__back {
    left: 0;
  }
}