.loanApp {
  flex-grow: 1;
  position: relative;
  .MuiStepper-root {
    margin-top: 50px;
    width: 50%;
    .MuiSvgIcon-root {
      color: $blue-pastel;
    }
    .MuiStepIcon-text {
      fill: $blue;
    }
    .MuiStepLabel-label {
      color: $blue;
      font-weight: bold;
      font-family: $Montserrat-alt;
    }
  }
  .MuiStepper-root .Mui-completed {
    .MuiSvgIcon-root {
      color: $green;
      transition: 0.5s;
    }
    .MuiStepLabel-label {
      color: $green;
      transition: 0.5s;
    }
  }
  .MuiGrid--root {
    width: 100%;
  }
}
.loanApp__stepper {
  margin-top: 50px !important;
}
.loanApp__application {
  background-color: $blue-pastel;
  padding: 25px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
.loanApp__help {
  background-color: $blue;
  border-radius: 30px;
  padding: 25px;
  .hr__line {
    color: white;
  }
  .title {
    color: white;
  }
  .text {
    color: white;
  }
}
.loanApp__help__cta {
  display: flex;
  align-items: center;
  color: white;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.25rem;
  svg {
    margin-right: 15px;
  }
  &:hover {
    color: white;
  }
}
.loanApp__step1 {
  .MuiFormControl-root {
    display: block;
    margin: 20px 0;
  }
}
.loanApp__step2__payment {
  @include flex-center;
  flex-direction: column;
  background-color: white;
  padding: 25px;
  border-radius: 30px;
  .text {
    color: $blue;
  }
}
.loanApp__step3 {
  @include flex-center;
  flex-direction: column;
  img {
    height: 50px;
    object-fit: contain;
  }
}
.loanApp__dialog {
  .MuiDialog-paper {
    padding: 35px;
  }
  canvas {
    border: 2px solid $blue-pastel;
  }
}
.payment__made {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 899px){
  .loanApp {
    .MuiStepper-root {
      width: 100%;
    }
    .MuiGrid-root {
      width: 100%;
    }
  }
}
