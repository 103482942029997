.loan__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  .img {
    height: 33%;
    width: 33;
  }
}
.loan__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 0;
  }
}
.loan__container {
  flex-grow: 1;
  position: relative;
  background-image: url("../../assets/background.svg");
  background-position: right;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .loan__header {
    display: flex;
    align-items: center;
    background-color: $blue-pastel;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 25px;
    gap: 30px;
    justify-content: space-between;
    .loan__number {
      display: flex;
    }
    .loan__coin {
      height: 60px;
      width: 60px;
      margin-right: 10px;
    }
  }
  .loan__card {
    background-color: $blue;
    position: relative;
    bottom: 15px;
    padding: 25px;
    border-radius: 30px;

    .loan__card__item {
      display: flex;
      flex-direction: column;
      color: white;
      span {
        font-weight: bold;
      }
    }
  }
  .chip-paid {
    color: $green;
    border: 1px solid $green;
  }
  .chip-late {
    color: $red;
    border: 1px solid $red;
  }
  .chip-refunded {
    color: $orange;
    border: 1px solid $orange;
  }
}
.loan__withdrawal {
  width: 40%;
}

.loan__pm__cta {
  padding: 5px 10px;
  border: 1px solid $blue;
  background-color: transparent;
}

.loan__pm {
  .MuiDialog-paper {
    padding: 35px;
    text-align: center;
  }
}

.loan__wallet,
.loan__payoff__dialog {
  .MuiDialog-paper {
    padding: 35px;
    text-align: center;
  }
  p {
    text-align: justify;
  }
}
@media only screen and (max-width: 899px) {
  .loan__withdrawal {
    width: 100%;
  }
}
