// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&display=swap");
$Montserrat: "Montserrat", sans-serif;
$Montserrat-alt: "Montserrat Alternates", sans-serif;
// Colors
$blue: #076ae1;
$blue-light: #35a0ee;
$blue-pastel: #f1f5fe;
$green: #00ff38;
$red: red;
$grey: #a4aeb4;
$red: red;
$orange: orange;
$lightgreen: lightgreen;
