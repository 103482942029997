.login {
    background-color: $blue-pastel;
    height: 100vh;
    @include flex-center;
}
.login__container {
    background-color: $blue;
    padding: 20px;
    width: 500px;
    max-width: 80%;
    flex-direction: column;
    @include modal-radius;
    @include flex-center;
}
.login__title {
    font-family: $Montserrat-alt;
    color: white;
    margin: 40px 0;
}
.login__input {
    color: white !important;
    padding: 10px;
    &::before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
    }
}
.login__options {
    text-align: center;
    p {
        color: white;
        cursor: pointer;
    }
}
.login__dialog {
    padding: 20px;
}
.login__reset__cta {
    float: right;
}