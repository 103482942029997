.account {
  flex-grow: 1;
  position: relative;
  .MuiInput-root {
    margin-top: 20px;
  }
  .form__spacing {
    display: flex;
    gap: 50px;
  }
}
.account__reset {
  width: 40%;
}
.account__right {
  img {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.account__status {
  font-weight: bold;
  span {
    display: flex;
    align-items: center;
  }
}
.account__status__progress {
  color: $blue;
  font-weight: bold;
}
.account__verification {
  img {
    max-width: 50%;
  }
}
.countdown__timer {
  color: $blue;
  font-weight: bold;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .account__right {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .account__reset {
    width: 100%;
  }
}