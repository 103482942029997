// Defaults
body,
html,
section {
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
:focus {
  outline: none;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
button {
  font-family: inherit;
  cursor: pointer !important;
  border: none;
}

* {
  font-family: $Montserrat;
}
// Classes
.button-light {
  display: inline-block;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 20px;
  color: $blue;
  background-color: white;
  margin: 25px 5px;
  &:disabled {
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
      transform: scale(1);
      cursor: not-allowed !important;
    }
  }
  &:hover {
    opacity: 0.85;
    transform: 0.2s;
    transform: scale(1.015);
  }
}
.button-semi {
  display: inline-block;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 20px;
  color: $blue-light;
  background-color: $blue-pastel;
  margin: 25px 5px;
  &:disabled {
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
      transform: scale(1);
      cursor: not-allowed !important;
    }
  }
  &:hover {
    opacity: 0.85;
    transform: 0.2s;
    transform: scale(1.015);
  }
}
.button-dark {
  display: inline-block;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 20px;
  color: white;
  background-color: $blue;
  margin: 25px 5px;
  &:disabled {
    opacity: 0.3;
    &:hover {
      opacity: 0.3;
      transform: scale(1);
      cursor: not-allowed !important;
    }
  }
  &:hover {
    opacity: 0.85;
    transform: 0.2s;
    transform: scale(1.015);
  }
}
.title {
  font-family: $Montserrat-alt;
  font-weight: bold;
}
.text {
  color: $grey;
}
.image {
  max-width: 100%;
}
.color__red {
  color: $red;
}
.color__orange {
  color: $orange;
}
.color__green {
  color: $green;
}
.color__lightgreen {
  color: $lightgreen;
}
.margin__none {
  margin: 0;
}
// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin modal-radius {
  border-radius: 10px;
}
@mixin title-font {
  font-family: $Montserrat-alt;
  font-weight: bold;
}
// Mui
.MuiPhoneNumber-flagButton {
    width: min-content;
}
