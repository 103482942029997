.chargify__container {
  font-family: "Lato", sans-serif;
  
  .chargify-js-wrapper {
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    width: fit-content;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .chargify-js-content {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 75%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.175);
    margin: 0 auto;
    min-width: 300px;
    border-top: 5px solid #076ae1;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .chargify__form {
    display: flex;
    // transition: visibility 0s opacity 0.5s linear;
  }

  .chargify__form iframe {
    width: 100% !important;
  }

  .cardfront {
    display: flex;
    flex-flow: column;
  }

  .cardback div {
    margin-right: 15px;
  }

  .cardback div:last-child {
    margin-right: 0;
  }

  .chargify__name {
    display: flex;
    flex-flow: row;
    padding-bottom: 20px;
  }

  .chargify__name div {
    margin-right: 15px;
    width: 100%;
  }

  .chargify__name div:last-child {
    margin-right: 0;
    width: 100%;
  }

  .chargify__address {
    padding-bottom: 20px;
  }

  .chargify__address__2 {
    display: flex;
    flex-flow: row;
  }

  .chargify__address__2 div {
    margin-right: 15px;
    width: 100%;
  }

  .chargify__address__2 div:last-child {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
