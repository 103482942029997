.sidebar {
    height: 100vh;
    background-color: $blue;
    width: 20vw;
    display: flex;
    position: fixed;
}
.sidebar__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 40px;
}
.sidebar__logo {
    max-width: 100%;
}
.sidebar__nav__container {
    a {
        display: block;
        display: flex;
        align-items: center;
        font-family: $Montserrat-alt;
        font-weight: bold;
        color: white;
        margin: 20px 0;
        opacity: 0.5;
        &.active {
            opacity: 1;
        }
        img {
            margin-right: 20px;
        }
    }
}
.sidebar__logout {
    display: flex;
    align-items: center;
    font-family: $Montserrat-alt;
    font-weight: bold;
    color: white;
    margin: 20px 0;
    cursor: pointer;
    img {
        margin-right: 20px;
    }
}
@media only screen and (max-width: 999px) {
  .sidebar {
    height: 100px;
    width: 100vw;
    bottom: 0;
    display: block;
    z-index: 999;
  }
  .sidebar__container {
    margin: 0;
  }
  .sidebar__logo {
    display: none;
  }
  .sidebar__nav__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .sidebar__nav__container a img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}