.layout__container {
    display: flex;
    overflow: hidden;
}
.layout__page {
    width: 80vw;
    padding: 30px;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-left: 20vw;
    height: 100vh;
}
.loading__container {
    @include flex-center;
    height: 100vh;
}
@media only screen and (max-width: 999px) {
  .layout__page {
    width: 100vw;
    margin-left: auto;
    height: calc(100vh - 150px);
  }
}